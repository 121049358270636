const colors = {
  50: "#bbeacc",
  100: "#a5e3bb",
  200: "#77d598",
  300: "#61ce87",
  400: "#4ac776",
  500: "#1DB954",
  600: "#1aa74c",
  700: "#179443",
  800: "#116f32",
  900: "#0f5d2a",
};
export default colors;
